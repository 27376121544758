<template>
  <div class="mt-8 pb-10 px-8">
    <trac-loading v-show="loading" />
    <trac-back-button>Back</trac-back-button>

    <div class="mt-5 mb-2 text-base font-medium">
      Complete KYC by verifying the following.
    </div>
    <div class="flex flex-col justify-center items-center">
      <h1 class="mt-10 text-primaryBlue font-medium">
        Verify Email
      </h1>
      <svg class="w-16 h-16 mt-5 mb-5" viewBox="0 0 78 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="74.1071" height="7.41071" fill="#B2E7FF" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M9.3953 2H68.5577C72.6251 2 75.953 5.32788 75.953 9.3953V53.7671C75.953 57.8345 72.6251 61.1624 68.5577 61.1624H9.3953C5.32788 61.1624 2 57.8345 2 53.7671V9.3953C2 5.32788 5.32788 2 9.3953 2Z"
          stroke="#253B95" stroke-width="3.69765" stroke-linecap="round" stroke-linejoin="round" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M76.1071 9.39551L39.0536 35.2791L2 9.39551" fill="#B2E7FF" />
        <path d="M76.1071 9.39551L39.0536 35.2791L2 9.39551" stroke="#253B95" stroke-width="3.69765"
          stroke-linecap="round" stroke-linejoin="round" />
      </svg>

      <div class="text-xs font-light mt-3">
        Please enter the 4-digit code sent to your email
      </div>
      <div>
        <OtpInput class="mt-10" :inputClasses="!error ? 'otp-input' : 'otp-input error'" :numInputs="4" separator="-"
          :shouldAutoFocus="true" @on-complete="handleOnComplete" />
      </div>
      <p v-if="error" class=" text-secondaryRed text-xs mt-5 font-medium">
        Number does not match with the OTP sent to your mail
      </p>
      <p class="  text-xs mt-10 font-medium">
        Didn’t recieve any mail?
        <b @click="$router.go(-1)" class=" text-primaryBlue cursor-pointer">Resend</b>.
      </p>

      <trac-modal v-if="successModal" :cancel="false">
        <div class="flex flex-col justify-center items-center">
          <svg class="w-16 h-16 mt-20" viewBox="0 0 170 157" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="81.4572" cy="80.1007" r="43.2554" fill="#11A529" fill-opacity="0.12" stroke="#B2E7FF"
              stroke-width="1.33329" />
            <path d="M60.3086 81.1842L68.4863 91.4149C69.5953 92.8022 71.6337 92.9931 72.981 91.8358L103.688 65.459"
              stroke="#253B95" stroke-width="5.33316" />
            <path
              d="M30.1113 29.4553C30.8623 32.1926 37.0518 37.7793 36.9784 28.2139C36.905 18.6486 40.7531 20.6235 44.679 22.2492"
              stroke="#0143AA" stroke-width="1.00759" />
            <path
              d="M78.4115 18.3942C80.8891 21.0813 91.2728 23.8118 85.5327 12.9136C79.7925 2.01542 85.3621 1.99895 90.8141 1.53712"
              stroke="#0143AA" stroke-width="1.29746" />
            <path
              d="M19.2072 98.4257C15.6305 95.1111 1.53402 92.5668 10.3098 106.461C19.0855 120.355 11.6796 120.96 4.47651 122.145"
              stroke="#0143AA" stroke-width="1.731" />
            <path
              d="M151.657 100.832C146.822 101.466 135.86 110.687 152.156 112.81C168.451 114.933 164.186 121.018 160.497 127.317"
              stroke="#B2E7FF" stroke-width="1.731" />
            <circle cx="64.5282" cy="137.579" r="3.79573" fill="#253B95" />
            <circle cx="87.8434" cy="148.578" r="7.59146" fill="#B2E7FF" />
            <circle cx="28.7391" cy="52.9891" r="3.79573" fill="#06B6CB" />
            <circle cx="142.612" cy="66.0033" r="7.04922" fill="#06B6CB" />
            <path
              d="M117.125 17.4727L119.468 23.8053L125.801 26.1486L119.468 28.4919L117.125 34.8246L114.782 28.4919L108.449 26.1486L114.782 23.8053L117.125 17.4727Z"
              fill="#06B6CB" />
            <path
              d="M4.88023 49.1934L6.19833 52.7555L9.76045 54.0736L6.19833 55.3917L4.88023 58.9538L3.56212 55.3917L0 54.0736L3.56212 52.7555L4.88023 49.1934Z"
              fill="#06B6CB" />
            <path
              d="M126.343 115.891L128.833 122.619L135.561 125.109L128.833 127.599L126.343 134.327L123.853 127.599L117.125 125.109L123.853 122.619L126.343 115.891Z"
              fill="#013E9E" />
            <path
              d="M31.8258 132.781L34.9922 136.864L40.1526 137.118L36.0699 140.284L35.8161 145.445L32.6497 141.362L27.4893 141.108L31.572 137.942L31.8258 132.781Z"
              fill="#013E9E" />
          </svg>

          <p class="text-center tracking-normal text-sm mt-8">
            Your Email has been verified succesfully
          </p>
          <trac-button @click.native="$router.push({ name: 'VerifyPhone' })" class="w-32 mt-8 mb-6" type="submit"><span
              class="px-12  text-center uppercase">Verify Phone Number</span></trac-button>

          <p @click="$router.push({ name: 'Kyc', params: {custom: isCustomKycRoute}  })"
            class="text-center text-primaryBlue tracking-normal  font-medium cursor-pointer text-base mt-3 mb-5">
            Back to KYC task
          </p>
        </div>
      </trac-modal>
    </div>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";

export default {
  components: {
    OtpInput,
  },
  data() {
    return {
      error: false,
      successModal: false,
      loading: false,
      isCustomKycRoute: false,
    };
  },
  async created() {
    this.isCustomKycRoute = this.$route.query.custom;
  },
  methods: {
    async handleOnComplete(value) {
      this.loading = true;
      let payload = {
        verificationCode: value,
        businessId: GET_USER_BUSINESS_ID(),
      };
      let res = await this.$store.dispatch("VERIFY_OTP", payload);
      this.loading = false;
      if (!res.status) {
        this.error = true;
      } else {
        if (this.isCustomKycRoute) {
          this.$router.push({
            name: "VerifyPhone", query: {
              custom: true,
            }
          });
        } else {
          this.successModal = true;
        }
      }
    },
  },
};
</script>

<style>
.otp-input {
  width: 60px;
  height: 60px;
  padding: 8px;
  margin: 0 15px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.error {
  border: 1px solid red !important;
}
</style>
